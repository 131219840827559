<template>
  <b-container fluid>
    <hr/>
    <b-row class="mt-1">
      <b-col cols="12">
        <b-card-actions
            ref="cardAction"
            title="Kurum Listesi"
            @refresh="refreshStop('cardAction')"
        >
          <hr/>
          <b-card-body class="top-card-body">
            <b-row>
              <b-col lg="6" md="6" sm="12" cols="12">
                <b-form-group class="mr-1 mb-md-0 per-page-group"
                              :style="{width : width < 380 ? '100%': width < 1070 ? '70%': width < 1500 ? '50%':''}"
                >
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12" cols="12">
                <b-input-group size="sm" class="search-bar"
                               :style="{width : width < 300 ? '100%': width< 1100 ? '60%':''}"
                >
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="search-input mb-0"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input id="filterInput" v-model="filter" type="search"/>
                </b-input-group>
              </b-col>
            </b-row>
          </b-card-body>
          <b-table
              striped
              hover
              responsive
              class="position-relative kurumlist-table"
              :per-page="perPage"
              :current-page="currentPage"
              :items="kurumList"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="dashGoo"
          >
            <template #cell(firmaKodu)="data">
              <p class="firmakodu-p">{{ data.item.firmaKodu }}</p>
            </template>
            <template #cell(odaSayisi)="data">
              <b-badge
                  :variant="
                  data.item.odaSayisi < 10
                    ? 'light-danger'
                    : data.item.odaSayisi < 50
                    ? 'light-primary'
                    : 'light-success'
                "
              >
                {{ data.item.odaSayisi }}
              </b-badge>
            </template>
            <template #cell(unvan)="data">
              <b-badge class="unvan-p" variant="light-secondary">
                {{ data.item.unvan }}
              </b-badge>
            </template>
            <template #cell(adres)="data">
              <p>{{ data.item.adres }}</p>
            </template>
            <template #cell(telefon)="data">
              <p>{{ data.item.telefon }}</p>
            </template>
            <template #cell(siteURL)="data">
              <a :href="data.item.siteURL" target="_blank">{{
                  data.item.siteURL
                }}</a>
            </template>
            <template #cell(postAction)="data">
              <p>
                {{
                  data.item.postAction == 'https://sanalpos2.ziraatbank.com.tr'
                      ? 'Ziraat Bankası'
                      : data.item.postAction == 'https://sanalpos.halkbank.com.tr'
                          ? 'Halk Bankası'
                          : ''
                }}
              </p>
            </template>
            <template #cell(invoiceServer)="data">
              <p>{{ 'Uyum Soft' || data.item.invoiceServer }}</p>
            </template>
            <template #cell(status)="data">
              <b-button
                  v-if="roleControl && data.item.domain"
                  v-b-tooltip.hover.left="'Host Sil'"
                  size="sm"
                  variant="gradient-danger"
                  class="btn-icon rounded-circle"
                  @click="hostRemove(data.item)"
              >
                <feather-icon icon="XIcon"/>
              </b-button>
              <b-button
                  @click="dashGo(data.item.firmaKodu)"
                  size="sm"
                  variant="gradient-success"
                  class="btn-icon rounded-circle"
              >
                <feather-icon icon="GridIcon"/>
              </b-button>
              <b-button
                  to="/userList"
                  size="sm"
                  variant="gradient-primary"
                  class="btn-icon rounded-circle"
                  @click="setFirmaKodu(data.item.firmaKodu)"
              >
                <feather-icon icon="UsersIcon"/>
              </b-button>
              <b-button
                  v-if="roleControl"
                  :to="'/kurumUpdate/' + data.item.firmaKodu"
                  size="sm"
                  variant="gradient-warning"
                  class="btn-icon rounded-circle"
              >
                <feather-icon icon="EditIcon"/>
              </b-button>
              <b-button
                  v-if="roleControl"
                  @click="firmaRemove(data.item.firmaKodu)"
                  size="sm"
                  variant="gradient-danger"
                  class="btn-icon rounded-circle"
              >
                <feather-icon icon="TrashIcon"/>
              </b-button>
            </template>
          </b-table>
          <hr/>
          <b-card-body
              class="bottom-card-body d-flex justify-content-between flex-wrap pt-0"
          >
            <p class="text-muted">Toplam {{ totalRows }} Kayıt bulundu</p>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-card-body>
        </b-card-actions>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import {
  BRow,
  BCol,
  BContainer,
  BCard,
  BTable,
  BBadge,
  BForm,
  BButton,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BAvatar,
  BCardBody, VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BInputGroupAppend,
    BInputGroupPrepend,
    BCardActions,
    BCard,
    BContainer,
    BRow,
    BCol,
    BTable,
    BBadge,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BAvatar,
    BCardBody,
  },
  data() {
    return {
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100, 200, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'firmaKodu',
          label: 'FİRMA KODU',
          sortable: true,
        },
        {
          key: 'odaSayisi',
          label: 'ODA SAYISI',
          sortable: true,
        },
        {
          key: 'unvan',
          label: 'ÜNVAN',
          sortable: true,
        },
        {
          key: 'adres',
          label: 'ADRES',
          sortable: true,
        },
        {
          key: 'telefon',
          label: 'TELEFON',
          sortable: true,
        },
        {
          key: 'siteURL',
          label: 'WEB ADRESİ',
          sortable: true,
        },
        {
          key: 'postAction',
          label: 'BANKA',
          sortable: true,
        },
        {
          key: 'invoiceServer',
          label: 'FATURA',
          sortable: true,
        },
        {
          key: 'status',
          label: 'İŞLEM',
          sortable: false,
        },
      ],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  mounted() {
    this.totalRows = this.kurumListCount
  },
  computed: {
    roleControl() {
      return store.getters.getRole === '1'
    },
    kurumList() {
      return store.getters.getKurumList
    },
    kurumListCount() {
      return store.getters.getKurumList.length
    },
    sortOptions() {
      return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({
            text: f.label,
            value: f.key,
          }))
    },
  },
  methods: {
    setFirmaKodu(e) {
      localStorage.setItem('raporKurumKodu', e)
    },
    dashGoo(e) {
      store.state.firmaPreview = true
      localStorage.setItem('raporKurumKodu', e.firmaKodu)
      router.push('kurumDetay')
    },
    dashGo(e) {
      store.state.firmaPreview = true
      localStorage.setItem('raporKurumKodu', e)
      router.push('kurumDetay')
    },
    hostRemove(e) {
      this.$swal({
        title: `${e.domain} İsimli Host Silinecektir.`,
        text: 'Bu İşlem Geri Alınamaz',
        icon: 'error',
        confirmButtonText: 'Evet Sil!',
        cancelButtonText: 'Vazgeç',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCloseButton: true,
        showCancelButton: true,
        buttonsStyling: false,
      })
          .then(res => {
            if (res.isConfirmed === true) {
              this.$swal({
                title: e.domain || 'Domain Mevcut Değil',
                html:
                    'Onay için domain adını inputa giriniz.'
                    + '<input class="mt-1 w-100" type="text" id="domainRemoveInput" autocomplete="off">',
                icon: 'error',
                confirmButtonText: 'Sil!',
                cancelButtonText: 'Vazgeç',
                confirmButtonAriaLabel: 'Thumbs up, great!',
                cancelButtonAriaLabel: 'Thumbs down',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                showCloseButton: true,
                showCancelButton: true,
                buttonsStyling: false,
              })
                  .then(result => {
                    if (result.isConfirmed === true) {
                      const removeDomainName = document.getElementById('domainRemoveInput').value
                      if (removeDomainName === e.domain) {
                        store
                            .dispatch('deleteHosting', {
                              hostUser: e.hostUser,
                            })
                            .then((res, position = 'bottom-right') => {
                              store
                                  .dispatch('dashIn')
                                  .then((res, position = 'bottom-right') => {
                                    this.totalRows = res.kurumListesi.length
                                    const mesaj = store.getters.notificationSettings(res)
                                    if (mesaj) {
                                      this.$toast(
                                          {
                                            component: ToastificationContent,
                                            props: {
                                              title: 'Başarılı',
                                              text: mesaj,
                                              icon: 'ThumbsUpIcon',
                                              variant: 'success',
                                            },
                                          },
                                          { position }
                                      )
                                    }
                                  })
                                  .catch((err) => {
                                    const mesaj =
                                        this.$store.getters.notificationSettings(err)
                                    if (mesaj) {
                                      this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                          title: 'Hata!',
                                          text: mesaj,
                                          icon: 'ThumbsDownIcon',
                                          variant: 'danger',
                                        },
                                      })
                                    }
                                  })
                              const mesaj = store.getters.notificationSettings(res)
                              if (mesaj) {
                                this.$toast(
                                    {
                                      component: ToastificationContent,
                                      props: {
                                        title: 'Başarılı',
                                        text: mesaj,
                                        icon: 'ThumbsUpIcon',
                                        variant: 'success',
                                      },
                                    },
                                    { position }
                                )
                              }
                            })
                            .catch(err => {
                              const mesaj = this.$store.getters.notificationSettings(err)
                              if (mesaj) {
                                this.$toast({
                                  component: ToastificationContent,
                                  props: {
                                    title: 'Hata!',
                                    text: mesaj,
                                    icon: 'ThumbsDownIcon',
                                    variant: 'danger',
                                  },
                                })
                              }
                            })
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: 'Domain Uyuşmazlığı',
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    }
                  })
            }
          })
    },
    firmaRemove(e) {
      this.$swal({
        title: store.getters.getKurumList.find(x => x.firmaKodu === e).unvan,
        text: ' Silmek İstediğine Eminmisin? Bu İşlem Geri Alınamaz',
        icon: 'error',
        confirmButtonText: 'Evet Sil!',
        cancelButtonText: 'Vazgeç',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCloseButton: true,
        showCancelButton: true,
        buttonsStyling: false,
      })
          .then((res) => {
            if (res.isConfirmed === true) {
              this.$swal({
                title: 'Silmek Üzeresin',
                text: ' Bu İşin Şakası Olmaz Ona Göre',
                icon: 'error',
                confirmButtonText: 'Gözü Karattım!',
                cancelButtonText: 'Vazgeç',
                confirmButtonAriaLabel: 'Thumbs up, great!',
                cancelButtonAriaLabel: 'Thumbs down',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                showCloseButton: true,
                showCancelButton: true,
                buttonsStyling: false,
              })
                  .then((result) => {
                    if (result.isConfirmed === true) {
                      store
                          .dispatch('kurumRemove', {
                            firmaKodu: e,
                          })
                          .then((res, position = 'bottom-right') => {
                            store
                                .dispatch('dashIn')
                                .then((res, position = 'bottom-right') => {
                                  this.totalRows = res.kurumListesi.length
                                  const mesaj = store.getters.notificationSettings(res)
                                  if (mesaj) {
                                    this.$toast(
                                        {
                                          component: ToastificationContent,
                                          props: {
                                            title: 'Başarılı',
                                            text: mesaj,
                                            icon: 'ThumbsUpIcon',
                                            variant: 'success',
                                          },
                                        },
                                        { position }
                                    )
                                  }
                                })
                                .catch((err) => {
                                  const mesaj =
                                      this.$store.getters.notificationSettings(err)
                                  if (mesaj) {
                                    this.$toast({
                                      component: ToastificationContent,
                                      props: {
                                        title: 'Hata!',
                                        text: mesaj,
                                        icon: 'ThumbsDownIcon',
                                        variant: 'danger',
                                      },
                                    })
                                  }
                                })
                            const mesaj = store.getters.notificationSettings(res)
                            if (mesaj) {
                              this.$toast(
                                  {
                                    component: ToastificationContent,
                                    props: {
                                      title: 'Başarılı',
                                      text: mesaj,
                                      icon: 'ThumbsUpIcon',
                                      variant: 'success',
                                    },
                                  },
                                  { position }
                              )
                            }
                          })
                          .catch((err) => {
                            const mesaj = this.$store.getters.notificationSettings(err)
                            if (mesaj) {
                              this.$toast({
                                component: ToastificationContent,
                                props: {
                                  title: 'Hata!',
                                  text: mesaj,
                                  icon: 'ThumbsDownIcon',
                                  variant: 'danger',
                                },
                              })
                            }
                          })
                    }
                  })
            }
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 1000)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
  },
  created() {
    store.state.firmaPreview = false
    store
        .dispatch('dashIn')
        .then((res, position = 'bottom-right') => {
          this.totalRows = res.kurumListesi.length
          const mesaj = store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position }
            )
          }
        })
        .catch((err) => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style>
.kurumlist-table tr {
  /*padding: 0 !important;*/
  white-space: nowrap !important;
  text-align: center;
  padding: 0.2% 1% !important;
}

.kurumlist-table tr th {
  font-size: 12px !important;
  padding: 0.2% 1% !important;
  background-color: #9c9efe !important;
  color: white !important;
}

.kurumlist-table td {
  padding: 0.1% 5px !important;
}

.col-12 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.card-header .heading-elements {
  display: none !important;
}
</style>
<style scoped>
hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.search-input button {
  border-color: #b8b0d3 !important;
}

.long-row {
  width: 100% !important;
}

.table-hr {
  width: 100% !important;
  background-color: #ffffff;
  height: 1px !important;
}

.top-card-body {
  padding: 1% 0 !important;
}

.bottom-card-body {
  padding: 1% 0 !important;
}

.search-input {
  border-color: #c3baba !important;
}

.kurumlist-table .badge {
  margin: 0 !important;
}

.firmakodu-p {
  color: #6b728e;
}

.unvan-p {
  font-size: 13px;
  background-color: #3c79f5 !important;
  color: white !important;
}

.per-page-group {
  width: 35%;
}

.search-bar {
  float: right;
  width: 30%;
}
</style>
